// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Timetracker2 from "../../blocks/timetracker2/src/Timetracker2";
import Productdescription3 from "../../blocks/productdescription3/src/Productdescription3";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Elasticsearch2 from "../../blocks/elasticsearch2/src/Elasticsearch2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Game5api from "../../blocks/game5api/src/Game5api";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Playermatchup from "../../blocks/playermatchup/src/Playermatchup";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Game4api from "../../blocks/game4api/src/Game4api";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Leaderboard2 from "../../blocks/leaderboard2/src/Leaderboard2";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Webinars from "../../blocks/webinars/src/Webinars";
import Game7api from "../../blocks/game7api/src/Game7api";
import Testimonials2 from "../../blocks/testimonials2/src/Testimonials2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Freetoplay2 from "../../blocks/freetoplay2/src/Freetoplay2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Game8api from "../../blocks/game8api/src/Game8api";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Game2api from "../../blocks/game2api/src/Game2api";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Game3api from "../../blocks/game3api/src/Game3api";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Sorting from "../../blocks/sorting/src/Sorting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Scoring from "../../blocks/scoring/src/Scoring";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Game6api from "../../blocks/game6api/src/Game6api";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";



const routeMap = {
Timetracker2:{
 component:Timetracker2,
path:"/Timetracker2"},
Productdescription3:{
 component:Productdescription3,
path:"/Productdescription3"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Elasticsearch2:{
 component:Elasticsearch2,
path:"/Elasticsearch2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Game5api:{
 component:Game5api,
path:"/Game5api"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Playermatchup:{
 component:Playermatchup,
path:"/Playermatchup"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Game4api:{
 component:Game4api,
path:"/Game4api"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Leaderboard2:{
 component:Leaderboard2,
path:"/Leaderboard2"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Webinars:{
 component:Webinars,
path:"/Webinars"},
Game7api:{
 component:Game7api,
path:"/Game7api"},
Testimonials2:{
 component:Testimonials2,
path:"/Testimonials2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Freetoplay2:{
 component:Freetoplay2,
path:"/Freetoplay2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Game8api:{
 component:Game8api,
path:"/Game8api"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Game2api:{
 component:Game2api,
path:"/Game2api"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Game3api:{
 component:Game3api,
path:"/Game3api"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Scoring:{
 component:Scoring,
path:"/Scoring"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Game6api:{
 component:Game6api,
path:"/Game6api"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
component:Analytics,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
